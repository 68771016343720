p {
}

.mydt {
}

.mydt table {
}

.mydt th {
}

.DayPicker-Day {
  border-radius: unset;
}